import * as React from 'react';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';
import { updatePassword } from '../api';

const Profile = () => {
  const [password, setPassword] = React.useState(''); 
  const [newPassword, setNewPassword] = React.useState(''); 
  const [confirmPassword, setConfirmPassword] = React.useState('');
  const [msg, setMsg] = React.useState(''); 
  const navigate = useNavigate();
  const auth = useAuth();

  const changePassword = async () => {
    if(password.trim() === '' || newPassword.trim() === '' || confirmPassword.trim() === '') {
      setMsg('Please fill out all fields');
      return;
    }

    if(newPassword !== confirmPassword) {
      setMsg('Passwords do not match');
      return;
    }

    const response = await updatePassword({
      currentPassword: password,
      newPassword: newPassword
    })

    if(response?.status === 200) {
      navigate('/');
    } else {
      const data = await response.json();
      setMsg(data.message);
    }

  }

  const handleChange = (event, fn) => {
    fn(event.target.value);
  };

  return (
    <div>
      <div style={{ position: 'relative' }} m={4}>
        <IconButton 
          style={{ position: 'fixed', top: '10px', right: '10px' }} 
          onClick={() => { navigate('/')}}
        >
          <HighlightOffIcon fontSize="large" />
        </IconButton>
      </div>
      <Box 
        display="flex"
        flexDirection="column"
        justifyContent="flex-start"
        alignItems="center"
        height="100%"
        sx={{ marginTop: '10%' }}
        paddingBottom = {`${100}px`}
      >
        <Grid container spacing={2}>
          <Grid item xs={0} md={3} />   
          <Grid item xs={12} md={6} sx={{ textAlign: 'left' }}>
            <Box justifyContent="left" p={2} >
              <h2>
                Account Profile
              </h2>
            </Box>
            <Box justifyContent="center" p={2}>
              <Divider style={{ width: '95%' }} /> {/* Adjust the width as needed */}
            </Box>

            <Box justifyContent="left" p={2} >
              <h4>
                User Information
              </h4>
              <Typography variant="body1" mt={2}>
                <b>First Name:</b> {auth.firstName}
              </Typography>
              <Typography variant="body1" mt={2}>
                <b>Last Name:</b> {auth.lastName}
              </Typography>
              <Typography variant="body1" mt={2}>
                <b>Email:</b> {auth.email}
              </Typography>
            </Box>

            <Box justifyContent="left" p={2} >
              <h4>
                Change Password
              </h4>
              <TextField
                type="password"
                label="Original Password"
                variant="outlined"
                fullWidth
                value={password}
                onChange={(event) => handleChange(event, setPassword)}
                sx={{ mt: 2 }}
              />
              <TextField
                type="password"
                label="New Password"
                variant="outlined"
                fullWidth
                value={newPassword}
                onChange={(event) => handleChange(event, setNewPassword)}
                sx={{ mt: 2 }}
              />
              <TextField
                type="password"
                label="Confirm Password"
                variant="outlined"
                fullWidth
                value={confirmPassword}
                onChange={(event) => handleChange(event, setConfirmPassword)}
                sx={{ mt: 2 }}
              />
              <Button
                variant="contained"
                color="primary"
                onClick={changePassword}
                sx={{ mt: 2 }}
              >
                Change Password
              </Button>
              <Typography variant="body1" mt={2}>
                {msg}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={0} md={3} />      
        </Grid>
      </Box>
    </div>
  );
};

export default Profile;