import { createContext, useContext, useMemo, useState } from 'react';

import { postLogin, postLogout, checkIfLoggedIn  } from '../api/index';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [email, setEmail] = useState();
  const [_id, setId] = useState();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [role, setRole] = useState();

  // call this function when you want to authenticate the user
  const login = async ({email, password}) => {
    try {
      const data = await postLogin({email, password});

      if(!data) {
        return false;
      }

      if(data.status === 403) {
        return {
          status: 403,
          msg: "User not verified."
        }
      }

      if(data.status === 400) {
        return {
          status: 400,
          msg: "Invalid email or password."
        }
      }

      if(data) {
        setEmail(data.email);
        setIsLoggedIn(true);
        setFirstName(data.firstName);
        setLastName(data.lastName);
        setId(data._id);
        return true;
      }

      return false;
    } catch(err) {
      console.log(err);
      return false;
    }
  }

  const checkLogin = async () => {
    try {
      const data = await checkIfLoggedIn();

      if(data) {  
        const {
          email,
          firstName,
          lastName,
          _id,
          role,
        } = data;

        setEmail(email);
        setFirstName(firstName);
        setLastName(lastName);
        setIsLoggedIn(true);
        setId(_id);
        setRole(role);
      } else {
        setEmail('');
        setFirstName('');
        setLastName('');
        setIsLoggedIn(false);
        setId('');
        setRole('');
      }

    } catch(err) {
      console.log(err);
    }
  }

  // call this function to sign out logged in user
  const logout = async () => {
    setEmail(null);
    setIsLoggedIn(false);
    setFirstName(null);
    setLastName(null);
    setId(null);
    await postLogout();
  };


  const value = useMemo(
    () => ({
      email,
      role,
      isLoggedIn,
      login,
      logout,
      firstName,
      lastName,
      checkLogin,
      _id
    }),
    [email, isLoggedIn, firstName, lastName, _id]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => useContext(AuthContext);
