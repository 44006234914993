import * as React from 'react';
import { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { getSnippet, postFlashcardResponse } from '../api/index';
import { shuffle } from 'lodash';
import { useStudy } from '../hooks/useStudy';
import { createEmptyCard, fsrs, generatorParameters } from 'ts-fsrs';
import { DisplayFlashcard } from '../utils/DisplayFlashcard';
import "./study.css";
import { config } from '../config';

const params = generatorParameters({ enable_fuzz: true });
const f = fsrs(params);
const FOOTER_HEIGHT = config.FOOTER_HEIGHT;

const buttonStyling = { 
  width: { xs: '100%', sm: 'auto' }, 
  mb: { xs: 2, sm: 0 }, 
  height: '40px'
};

const Study = (props) => {
  const [currentSnippet, setCurrentSnippet] = useState(null);
  const [currentFlashcard, setCurrentFlashcard] = useState(null); 
  const [showAnswer, setShowAnswer] = useState(false);
  const [frontTime, setFrontTime] = useState(null);
  const [backTime, setBackTime] = useState(null);
  const [frontTimeTotal, setFrontTimeTotal] = useState(0);
  const study = useStudy();
  const [studyQueue, setStudyQueue] = useState([]);
  const [pendingQueue, setPendingQueue] = useState([]);
  const [imageIndex, setImageIndex] = useState(0);
  const [seriesIndex, setSeriesIndex] = useState(0);
  
  const { studying, flashcards, setFlashcards } = study;

  const toggleShow = () => {
    setShowAnswer(!showAnswer);
    setFrontTimeTotal(new Date() - frontTime);
    setBackTime(new Date());
  }

  function isDueToday(due) {
    try {
      new Date(due);
    } catch(err) {
      throw new Error('Invalid date object');
    }

    if(due === null) return true;

    if(isNaN(due.getTime())) return true;

    const today = new Date();

    return due.getDate() === today.getDate() &&
      due.getMonth() === today.getMonth() &&
      due.getFullYear() === today.getFullYear();
  }

  const toggleAnswer = async (rating) => {
    setShowAnswer(false);

    const now = new Date();
    const scheduling_cards = f.repeat(currentFlashcard, now);

    // update global flashcard
    let flashcards = {...study.flashcards};
    flashcards[scheduling_cards[rating].card.snippet] = scheduling_cards[rating].card;

    study.setFlashcards(flashcards);

    scheduling_cards[rating].log.frontTime = frontTimeTotal;
    scheduling_cards[rating].log.backTime = new Date() - backTime;

    postFlashcardResponse(scheduling_cards[rating]);

    nextFlashcard(scheduling_cards[rating].card);
  }

  const nextFlashcard = async (priorFlashcard) => {
    let currenttime = new Date();

    setFrontTime(currenttime);

    let flashcard = null;
    let _pendingQueue = [...pendingQueue];
    let _studyQueue = [...studyQueue];

    if(isDueToday(priorFlashcard.due)) {
          // Insert the flashcard into the studyQueue
      _studyQueue.push(priorFlashcard);

      // Sort the studyQueue in ascending order of due
      _studyQueue.sort((a, b) => new Date(b.due) - new Date(a.due));
    }

    if(_pendingQueue.length === 0 && _studyQueue.length === 0) {
      study.stop();
      return;
    }

 
    if (_studyQueue.length && _studyQueue[_studyQueue.length - 1].due < currenttime) {
      flashcard = _studyQueue.pop();
      setStudyQueue(_studyQueue);
    } else if (_pendingQueue.length) {
      flashcard = _pendingQueue.pop();
      setPendingQueue(_pendingQueue);
    } else if (_studyQueue.length) {
      flashcard = _studyQueue.pop();
      setStudyQueue(_studyQueue);
    } 

    if(flashcard !== null) {
      let snippet = await getSnippet(flashcard.snippet);
      setCurrentFlashcard(flashcard);
      setCurrentSnippet(snippet);
    } else {
      if(_pendingQueue.length === 0 && _studyQueue.length === 0) {
        // last flashcard
        if(isDueToday(priorFlashcard.due)){
          setCurrentFlashcard({...currentFlashcard});
        } else {
          study.stop();
        }
      }
    }

    let _flashcards = {...flashcards};
    _flashcards[priorFlashcard.snippet] = priorFlashcard;
    setFlashcards(_flashcards);
    setStudyQueue(_studyQueue);
  }

  // Convert add flashcard data back to card
  const handler = (data, card) => {
    return {
      ...card,
      ...data
    }
  }

  useEffect(() => {
    const load = async () => {
      if(studying) {
        let flashcards = shuffle([...study.flashcardsToStudy]);

        for(let x = 0; x < flashcards.length; x++) {
          flashcards[x] = createEmptyCard(flashcards[x].due === null ? new Date() : new Date(flashcards[x].due), (data) => handler(data, flashcards[x]));
        }
        
        const newCurrentFlashcard = flashcards.pop();

        let snippet = await getSnippet(newCurrentFlashcard.snippet);

        setStudyQueue([]);
        setPendingQueue(flashcards);
        setCurrentFlashcard(newCurrentFlashcard);
        setCurrentSnippet(snippet);
      }
    }
    load();
  }, [studying]);

  useEffect(() => {
    const handleKeyDown = (e) => {
      switch (e.keyCode) {
        case 49: // '1'
          toggleAnswer(1);
          break;
        case 50: // '2'
          toggleAnswer(2);
          break;
        case 51: // '3'
          toggleAnswer(3);
          break;
        case 52: // '4'
          toggleAnswer(4);
          break;
        case 32: // 'Spacebar'
          toggleShow();
          break;
        case 38: // 'Up Arrow'
          console.log(currentSnippet)
          console.log(seriesIndex, imageIndex);
          if(!currentSnippet) return;

          e.preventDefault();

          if (imageIndex > 0) {
            setImageIndex(imageIndex - 1);
          } else if (seriesIndex > 0) {
            setSeriesIndex(seriesIndex - 1);
            setImageIndex(currentSnippet.series[seriesIndex - 1].images.length - 1);
          }

          break;
        case 40: // 'Down Arrow'
          console.log(currentSnippet);
          console.log(seriesIndex, imageIndex);
          if(!currentSnippet) return;

          e.preventDefault();

          if (imageIndex < currentSnippet?.series[seriesIndex]?.images?.length - 1) {
            setImageIndex(imageIndex + 1);
          } else if (seriesIndex < currentSnippet.series.length - 1) {
            setSeriesIndex(seriesIndex + 1);
            setImageIndex(0);
          }

          break;
        default:
          break;
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [imageIndex, seriesIndex, props.series]);

  return (
    <>
      <DisplayFlashcard 
        snippet={currentSnippet} 
        studying={studying}
        showAnswer={showAnswer}
        imageIndex={imageIndex}
        setImageIndex={setImageIndex}
        seriesIndex={seriesIndex}
        setSeriesIndex={setSeriesIndex}
      /> 

      <Box 
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        sx={{ 
          height:  `${FOOTER_HEIGHT}px`, 
          position: 'fixed', 
          left: 0, 
          bottom: 0, 
          right: 0, 
          backgroundColor: 'white', 
          color: 'black',
        }}
        py={2}
      >

        {!showAnswer 
          ? <Stack direction="row" spacing={{ xs: 1, sm: 2 }} mb={5}>
              <span className={study.studying ? '' : 'blurr'}>
                <Button 
                  variant="contained" 
                  color="success" 
                  onClick={toggleShow}
                  disabled={!study.studying}
                  sx={buttonStyling}
                >
                  Show Answer
                </Button>
              </span>
            </Stack>
          : <Stack direction="row" spacing={{ xs: 1, sm: 2 }} mb={5}>
              <Button 
                variant="contained" 
                color="error" 
                onClick={() => toggleAnswer(1)}
                sx={buttonStyling}
              >
                Again
              </Button>
              <Button 
                variant="contained" 
                color="warning"
                onClick={() => toggleAnswer(2)}
                sx={buttonStyling}
              >
                Hard
              </Button>
              <Button 
                variant="contained" 
                color="info"
                onClick={() => toggleAnswer(3)}
                sx={buttonStyling}
              >
                Good
              </Button>
              <Button 
                variant="contained" 
                color="success"
                onClick={() => toggleAnswer(4)}
                sx={buttonStyling}
              >
                Easy
              </Button>
            </Stack>
          }
      </Box>
    </>
  )
}

export default Study;