import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
import { Link, useParams } from 'react-router-dom';
import SectionHeader from './partials/SectionHeader';
import Button from '../elements/Button';
import { verifyUser } from '../../api';
import { config } from '../../config';

const propTypes = {
  ...SectionProps.types
}

const defaultProps = {
  ...SectionProps.defaults
}

// Functional component wrapper to use useParams
function VerifyUserSection() {
  const { id } = useParams();
  const [verified, setVerified] = useState(false);
  const [loading, setLoading] = useState(true); // Add loading state

  useEffect(() => {
    // Define the async function inside useEffect
    const fetchVerification = async () => {
      try {
        setVerified(await verifyUser({ id })); 
      } catch (error) {
        console.error("Failed to verify user:", error);
        setVerified(false); 
      } finally {
        setLoading(false); // Ensure loading is set to false after operation
      }
    };

    fetchVerification();
  }, [id]); // Dependency array, re-run if `id` changes

  // Handle loading state
  if (loading) {
    return <div>Verifying...</div>;
  }

  // Pass the id and verified state as props to the class component
  return <VerifyUserSectionClass id={id} verified={verified} />;
}


class VerifyUserSectionClass extends React.Component {

  render() {

    const {
      className,
      topOuterDivider,
      bottomOuterDivider,      
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      verified,
      ...props
    } = this.props;

    const outerClasses = classNames(
      'signin section',
      topOuterDivider && 'has-top-divider',
      bottomOuterDivider && 'has-bottom-divider',
      hasBgColor && 'has-bg-color',
      invertColor && 'invert-color',
      className
    );

    const innerClasses = classNames(
      'signin-inner section-inner',
      topDivider && 'has-top-divider',
      bottomDivider && 'has-bottom-divider'
    );

    const sectionHeader = {
      title: verified ? 'You have been verified.' : 'Failed to verify you! Sorry...'
    };

    return (
      <section
        {...props}
        className={outerClasses}
      >
        <div className="container">
          <div className={innerClasses}>
            <SectionHeader tag="h1" data={sectionHeader} className="center-content" />
            <div className="tiles-wrap">
              <div className="tiles-item">
                <div className="tiles-item-inner">
                  <div className="signin-bottom has-top-divider">
                    {
                      verified ?
                        <div className="pt-32 text-xs center-content text-color-low">
                          <Link to="/login/" className="func-link text-xs">
                            <Button className="button button-primary button-wide-mobile button-sm">Login</Button>  
                          </Link>
                        </div>
                      : null
                    }
                    {
                      !verified ?
                        <>
                          <div className="pt-32 text-xs center-content text-color-low">
                              Email us at {config.help_email} if you are having trouble verifying your account.
                          </div>
                          <div className="pt-32 text-xs center-content text-color-low">
                              Go to the {<Link to="/login/" className="func-link text-xs">login</Link>} or {<Link to="/" className="func-link text-xs">home</Link>} page.
                          </div>
                        </>
                      : null
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

VerifyUserSection.propTypes = propTypes;
VerifyUserSection.defaultProps = defaultProps;

export default VerifyUserSection;