import { createContext, useContext, useMemo, useState } from 'react';
import { loadData, postDeactiveAll, flagCategory  } from '../api/index';
import { getStartAndEndTimes } from '../utils/time';

const StudyContext = createContext();

export const StudyProvider = ({ children }) => {
    const [flashcards, setFlashcards] = useState([]);
    const [flashcardsToStudy, setFlashcardsToStudy] = useState([]);
    const [categories, setCategories] = useState([]);
    const [cardsToCategories, setCardsToCategories] = useState([]); 
    const [studying, setStudying] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const [loading, setLoading] = useState(false);

    const checkParentTreeCompletion = (_id, hashedCategories) => {
        if(_id === null) return;
        let childrenAllFlagged = true;
        const parent = hashedCategories[_id].parent;
        
        if(parent === null) return;

        for(let x = 0; x < hashedCategories[parent].children.length; x++) {
        if(hashedCategories[parent].children[x].published === false) continue;
        let childId = hashedCategories[parent].children[x].category;

        if(Boolean(hashedCategories[childId]?.flagged) === false) {
            childrenAllFlagged = false;
            break;
        }
        }

        hashedCategories[parent].flagged = childrenAllFlagged;

        checkParentTreeCompletion(parent, hashedCategories);
    }

    const load = async () => {
        setLoading(true);
        const result = await loadData(); // replace with your function that returns a Promise
        let hashedCategories = [];
        let hashedFlashcards = [];
        let hashedCardsToCategories = [];
        let missingCards = [];

        if(!result) return;

        for(let x = 0; x < result.flashcards.length; x++) {
            hashedFlashcards[result.flashcards[x].snippet] = result.flashcards[x];
        } 

        for(let x = 0; x < result.categories.length; x++) {
            const _id = result.categories[x]._id;
            hashedCategories[_id] = result.categories[x];

            for(let y = 0; y < result.categories[x].snippets.length; y++) {
                if(result.categories[x].snippets[y].published === true) {
                    hashedCardsToCategories[result.categories[x].snippets[y].snippet] = [];
                    hashedCardsToCategories[result.categories[x].snippets[y].snippet][_id] = false;
                }
            }
        }

        console.log(result);

        if(result?.flags?.categories?.length) {

            for(let x = 0; x < result.flags.categories.length; x++) {
                const _id = result.flags.categories[x].category;
                const flagged = Boolean(result.flags.categories[x].flagged);

                if(hashedCategories[_id] === undefined) continue;
                
                hashedCategories[_id].flagged = flagged;

                if(flagged === true) { 

                    for(let y = 0; y < hashedCategories[_id].snippets.length; y++) {                        

                        if(hashedCategories[_id].snippets[y].published === true) {
                                hashedCardsToCategories[hashedCategories[_id].snippets[y].snippet][_id] = true;                            if(!hashedFlashcards[hashedCategories[_id].snippets[y].snippet]) {
                                missingCards.push(hashedCategories[_id].snippets[y].snippet);
                                }
                        }
                        
                    }
                }
            }
        }

        // const cards = await loadFlashcards(missingCards);

        // if(cards) {
        //     for(let x = 0; x < cards.length; x++) {
        //         hashedFlashcards[cards[x]._id] = cards[x];
        //     }
        // }
        const leafs = result.categories.filter( c => c.snippets.length > 0);

        for(let x = 0; x < leafs.length; x++) {
            if(hashedCategories[leafs[x].parent] && hashedCategories[leafs[x].parent].active === undefined) {
                checkParentTreeCompletion(leafs[x]._id, hashedCategories);
            }
        }

        setCategories(hashedCategories);
        setFlashcards(hashedFlashcards);
        setCardsToCategories(hashedCardsToCategories);
        setLoaded(true);
        setLoading(false);
    };

    const deactivateAll = async () => {
        await postDeactiveAll();
        const updateCategories = { ...categories };
        const categoryKeys = Object.keys(updateCategories);
        const updateFlashcards = { ...flashcards };
        const flashcardKeys = Object.keys(flashcards);

        for(let x = 0; x < categoryKeys.length; x++) {
            if(updateCategories[categoryKeys[x]].flagged === true) {
                updateCategories[categoryKeys[x]].flagged = false;
            }
        }

        for(let x = 0; x < updateFlashcards.length; x++) {
            updateFlashcards[flashcardKeys[x]].active = false;
        }

        setCategories(updateCategories);
        setFlashcards(updateFlashcards);
    }

    const flagFlashards = async (_id, flag) => {  
        let updateCategories = { ...categories };
        const status = Boolean(!updateCategories[_id].flagged);
        let hashedCardsToCategories = {...cardsToCategories};
        let updateFlashcards = { ...flashcards };

        const data = await flagCategory(_id, status);

        const flashcardsToAdd = data.flashcards;

        for(let x = 0; x < flashcardsToAdd.length; x++) {
            updateFlashcards[flashcardsToAdd[x].snippet] = flashcardsToAdd[x];
        }

    
        if (updateCategories[_id]) {
            updateCategories[_id] = {
                ...updateCategories[_id],
                flagged: status
            };
        }

        const snippets = updateCategories[_id].snippets;

        for(let x = 0; x < snippets.length; x++) {
            if(snippets[x].published === false) continue;
            const snippet = snippets[x].snippet;
            hashedCardsToCategories[snippet][_id] = status;

            const checkIfAnyTrue = (obj) => {
                for (let key in obj) {
                    if (obj[key] === true) {
                    return true;
                    }
                }
                return false;
            };

            if(status === false) {
                if(checkIfAnyTrue(hashedCardsToCategories[snippet]) === true) {
                    updateFlashcards[snippet].active = true;
                } else {
                    updateFlashcards[snippet].active = false;
                }
            }
        }
    
        if(updateCategories[_id].parent !== null) {
            checkParentTreeCompletion(_id, updateCategories);
        }


        setFlashcards(updateFlashcards); 
        setCategories(updateCategories); 
        setCardsToCategories(hashedCardsToCategories);
    }

    const study = async (option) => {
        let cardsToStudy = [];
        let keys = Object.keys(flashcards);
        const { startOfDay, endOfDay } = getStartAndEndTimes(new Date());

        if(option === "all") {
            for(let x = 0; x < keys.length; x++) { 
                if(flashcards[keys[x]].active === false) continue;
                if(new Date(flashcards[keys[x]].due) === null || new Date(flashcards[keys[x]].due) < endOfDay) {
                  cardsToStudy.push(flashcards[keys[x]]);
                } 
            }
        } else if (option === "new") {
            for(let x = 0; x < keys.length; x++) {  
                if(flashcards[keys[x]].active === false) continue;
                if(flashcards[keys[x]].due === null) {
                    cardsToStudy.push(flashcards[keys[x]]);
                } 
            }
        } else if (option === "due") {
            for(let x = 0; x < keys.length; x++) {  
                if(flashcards[keys[x]].active === false) continue;
                if(new Date(flashcards[keys[x]].due) < endOfDay && new Date(flashcards[keys[x]].due) > startOfDay) {
                    cardsToStudy.push(flashcards[keys[x]]);
                } 
            }
        } else if (option === "overdue") {
            for(let x = 0; x < keys.length; x++) {  
                if(flashcards[keys[x]].active === false) continue;
                if(new Date(flashcards[keys[x]].due) < startOfDay) {
                    cardsToStudy.push(flashcards[keys[x]]);
                } 
            }
        } 

        await setFlashcardsToStudy(cardsToStudy);
        await setStudying(true);
    }

    const stop = () => {
        setStudying(false);
    }

    const value = useMemo(
        () => ({
            flashcards,
            setFlashcards,
            flashcardsToStudy,
            categories,
            setCategories,
            flagFlashards,
            study,
            studying,
            stop,
            load,
            loading,
            loaded,
            deactivateAll
        }),
        [flashcards, flashcardsToStudy, categories, studying]
    );

    return <StudyContext.Provider value={value}>{children}</StudyContext.Provider>;
};

export const useStudy = () => useContext(StudyContext);
