import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    palette: {
      primary: {
        main: '#123', // This will be the color of your AppBar
      },
    },
    zIndex: {
      drawer: 1200,
    },
  });

export default theme;