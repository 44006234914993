import React from 'react';
import ForgotPasswordForm from '../components/sections/ForgotPasswordForm';

class ForgotPassword extends React.Component {
  render() {
    return (
      <ForgotPasswordForm className="illustration-section-01" />
    );
  }
}

export default ForgotPassword;