import React from 'react';
import classNames from 'classnames';
import { SectionSplitProps } from '../../utils/SectionProps';
import demoVideo from './../../assets/images/demo.mp4';
import { Grid } from '@mui/material';

const propTypes = {
  ...SectionSplitProps.types
}

const defaultProps = {
  ...SectionSplitProps.defaults
}

class HeroSplit extends React.Component {

  render() {
    const {
      className,
      topOuterDivider,
      bottomOuterDivider,      
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      invertMobile,
      invertDesktop,
      alignTop,
      imageFill,
      ...props
    } = this.props;

    const outerClasses = classNames(
      'hero section',
      topOuterDivider && 'has-top-divider',
      bottomOuterDivider && 'has-bottom-divider',
      hasBgColor && 'has-bg-color',
      invertColor && 'invert-color',
      className
    );

    return (
      <section
        {...props}
        className={outerClasses}
      >
        <Grid 
          container 
          spacing={3} 
          alignItems="center" 
          justifyContent="center"          
          pt={3}
          style={{ height: '100vh' }}> 
          <Grid 
            item 
            xs={12} 
            md={3}
            container
            direction="column"
            alignItems="center"
            justifyContent="center"
            style={{ textAlign: 'center' }}>
              <h1 className="mt-0 mb-16">
                Radki
              </h1>
              <p className="mt-0 mb-32">
                Spaced-repetition flashcards for radiology.
              </p>
          </Grid>
          <Grid 
            item 
            xs={12} 
            md={6}
            container
            direction="column"
            alignItems="center"
            justifyContent="center">
            <div className="hero-figure split-item-image split-item-image-fill -element-01 reveal-from-bottom">
              <video autoPlay loop muted style={{ boxShadow: '0px 10px 15px rgb(0 , 0, 0)', borderRadius: '10px' }}>
                <source src={demoVideo} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
          </Grid>
        </Grid>

      </section>
    );
  }
}

HeroSplit.propTypes = propTypes;
HeroSplit.defaultProps = defaultProps;

export default HeroSplit;