import React, { forwardRef } from 'react';
import './scrollbar.css';

const ScrollBar = forwardRef((props, ref) => {

  if (!props?.snippet?.series) {
    return null;
  }

  if (props?.numberOfImages < 2) {
    return null;
  }

  if(props.thumbnail === true) {
    return null;
  }

  const scrollBarMargin = 10;

  const globalIndex = () => {
    let adjustScrollImageIndex = 0;
    for (let x = 0; x < props?.seriesIndex; x++) {
      adjustScrollImageIndex += props.snippet.series[x].images.length;
    }
    return adjustScrollImageIndex + props.imageIndex;
  };

  const height = (props.imageHeight - scrollBarMargin * 2) / props?.numberOfImages;

  return (
    <div
      className='scrollbar'
      style={{
        height: `${height}px`,
        marginTop: `${10 + globalIndex() * height}px`,
        marginBottom: '10px',
      }}
    />
  );
});

export default ScrollBar;