import React, { useState, useEffect } from 'react';
import Markdown from '../MarkDown';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';

const DisplayFlashcard = (props) => {
    let {
        seriesIndex,
        imageIndex,
        setImageIndex,
        setSeriesIndex
    } = props;

    const [imageHeight, setImageHeight] = useState(500);
    const [isHoveringImg, setIsHoveringImg] = useState(false);
    const [isHoveringScrollBar, setIsHoveringScrollBar] = useState(false);
    const [scrolling, setScrolling] = useState(false);
    
    useEffect(() => {
        const reset = () => {
            setSeriesIndex(0);
            setImageIndex(0);
            setImageHeight(500);
            setIsHoveringImg(false);
            setIsHoveringScrollBar(false);
            setScrolling(false);
        }

        reset();
    }, [props.snippet]);

    if(props.snippet === null) {
        return;
    }

    return (
        <div className={props.studying ? '' : 'blurr'}>
            <Box 
                display="flex"
                flexDirection="column"
                justifyContent="flex-start"
                alignItems="center"
                height="100%"
                sx={{ marginTop: '5%' }}
                paddingBottom = {`${100}px`}
            >
                <Grid container spacing={0}>
                    <Grid item xs={0} md={3} />   
                        <Grid item xs={12} md={6} sx={{ textAlign: 'left' }}>
                            <Box justifyContent="left" p={2} >
                                <Markdown 
                                    snippet={props.snippet}
                                    seriesIndex={seriesIndex}
                                    imageIndex={imageIndex}
                                    setSeriesIndex={setSeriesIndex}
                                    setImageIndex={setImageIndex}
                                    isHoveringImg={isHoveringImg}
                                    setIsHoveringImg={setIsHoveringImg}
                                    isHoveringScrollBar={isHoveringScrollBar}
                                    setIsHoveringScrollBar={setIsHoveringScrollBar}
                                    scrolling={scrolling}
                                    setScrolling={setScrolling}
                                    imageHeight={imageHeight}
                                    setImageHeight={setImageHeight}
                                > 
                                    {props?.snippet ? props?.snippet?.front : null}
                                </Markdown>
                            </Box>

                            <Box justifyContent="center" p={2}>
                                <Divider style={{ width: '95%' }} /> {/* Adjust the width as needed */}
                            </Box>

                            <Box justifyContent="left" p={2} sx={{ textAlign: 'left' }}>
                                <Markdown 
                                    snippet={props.snippet}
                                    seriesIndex={seriesIndex}
                                    imageIndex={imageIndex}
                                    setSeriesIndex={setSeriesIndex}
                                    setImageIndex={setImageIndex}
                                    isHoveringImg={isHoveringImg}
                                    setIsHoveringImg={setIsHoveringImg}
                                    isHoveringScrollBar={isHoveringScrollBar}
                                    setIsHoveringScrollBar={setIsHoveringScrollBar}
                                    scrolling={scrolling}
                                    setScrolling={setScrolling}
                                    imageHeight={imageHeight}
                                    setImageHeight={setImageHeight}
                                > 
                                    {props?.showAnswer ? props?.snippet ? props?.snippet.back : null : null}
                                </Markdown>
                            </Box>
                        </Grid>
                    <Grid item xs={0} md={3} />      
                </Grid>
            </Box>
        </div>
    );
};


export { DisplayFlashcard };