import React, { useState } from 'react';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
import { Link, useNavigate } from 'react-router-dom';
import SectionHeader from './partials/SectionHeader';
import Input from '../elements/Input';
import Button from '../elements/Button';
import Checkbox from '@mui/material/Checkbox';
import { postSignUp } from '../../api/index';

const propTypes = {
  ...SectionProps.types
}

const defaultProps = {
  ...SectionProps.defaults
}

const SignupForm = (props) => {
  const [agreeToTerms, setAgreeToTerms] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [msg, setMsg] = useState('');
  const navigate = useNavigate();

  const handleCheckboxChange = (e) => {
    setAgreeToTerms(e.target.checked);
  }

  const handleFirstNameChange = (e) => {
    setFirstName(e.target.value);
  }

  const handleLastNameChange = (e) => {
    setLastName(e.target.value);
  }

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  }

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await postSignUp({ agreeToTerms, firstName, lastName, email, password });
      if(response.status === 200) {
        navigate('/login');
      } else {
        const data = await response.json();
        setMsg(data.message);
      }
    } catch(err) {
      console.error(err);
    }
  }

  const {
    className,
    topOuterDivider,
    bottomOuterDivider,      
    topDivider,
    bottomDivider,
    hasBgColor,
    invertColor,
  } = props;

  const outerClasses = classNames(
    'signin section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  )

  const innerClasses = classNames(
    'signin-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  )

  const sectionHeader = {
    title: 'Sign up.',
  }

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader tag="h1" data={sectionHeader} className="center-content" />
          <div className="tiles-wrap">
            <div className="tiles-item">
              <div className="tiles-item-inner">
                <form onSubmit={handleSubmit}>
                  <fieldset>
                    <div className="mb-12">
                      <Input
                        label="First Name"
                        placeholder="First Name" 
                        labelHidden
                        required
                        onChange={handleFirstNameChange}
                      />
                    </div>
                    <div className="mb-12">
                      <Input
                        label="Last Name"
                        placeholder="Last Name" 
                        labelHidden
                        required
                        onChange={handleLastNameChange}
                      />
                    </div>
                    <div className="mb-12">
                      <Input
                        type="email"
                        label="Email"
                        placeholder="Email"
                        labelHidden
                        required
                        onChange={handleEmailChange}
                      />
                    </div>
                    <div className="mb-12">
                      <Input
                        type="password"
                        label="Password"
                        placeholder="Password"
                        labelHidden
                        required
                        onChange={handlePasswordChange}
                      />
                    </div>
                    <div className="mb-12">
                      <Checkbox onClick={handleCheckboxChange}/>
                      <a href="/terms-and-conditions" target="_blank" rel="noopener noreferrer">
                        Terms & Conditions
                      </a>
                      &nbsp;and&nbsp;
                      <a href="/privacy-policy" target="_blank" rel="noopener noreferrer">
                        Privacy Policy
                      </a>
                    </div>
                    <div className="mt-24 mb-32">
                      <Button disabled={!agreeToTerms} color="primary" wide>Sign up</Button>
                    </div>
                    {msg?.length > 0 && (<div className="mt-24 mb-32">
                      {msg}
                    </div>)}
                  </fieldset>
                </form>
                <div className="signin-bottom has-top-divider">
                  <div className="pt-32 text-xs center-content text-color-low">
                    Already have an account? <Link to="/login/" className="func-link">Login</Link>
                  </div>
                  <div className="pt-32 text-xs center-content text-color-low">
                    <Link to="/" className="func-link text-xs">Home</Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

SignupForm.propTypes = propTypes;
SignupForm.defaultProps = defaultProps;

export default SignupForm;