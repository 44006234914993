const { www } = require('../config.js').config;

const { 
  postLogin,
  postLogout,
  postSignUp,
  checkIfLoggedIn,
  verifyUser,
  resendEmailVerification,
  updatePassword,
  resetPassword
} = require('./account');

const getPublicCategories = async () => {
  const decks = await fetch(`${www}/api/user/categories`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
  })
  .then(response => response.json())
  .then(data => data.data);
  return  decks
}

const getFlashcards = async (snippets) => {
  let ids = encodeURIComponent(JSON.stringify(snippets));
  const flashcards = await fetch(`${www}/api/user/snippets/${ids}`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
  })
  .then(response => response.json())
  .then(data => data.data);
  return  flashcards.snippets;
}

/** 
 * returns { categories, flags, flashcards }
 */

const loadData = async() => {
  try {
    const data = await fetch(`${www}/api/user/load`, {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin' : www
      }
    });

    const response = await data.json();

    return  response.data;
  } catch(err) {
    console.log(err);
    return { categories: [], flags: [], flashcards: []} 
  }
}

const loadFlashcards = async(_ids) => {
  const url = new URL(`${www}/api/user/load/flashcards`);
  const data = await fetch(url, {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin' : '*'
    },
    body: JSON.stringify(_ids)
  });

  const response = await data.json();
  return  response.data;
}


const flagCategory = async(_id, status) => {
  try {
    const data = await fetch(`${www}/api/user/flag/${_id}`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin' : '*'
      },
      body: JSON.stringify({ _id, status })
    });
    const response = await data.json();
    return  response.data;
  } catch(err) {
    console.log(err);
  }
}

const getSnippet = async (_id) => {
  try {
    const data = await fetch(`${www}/api/user/snippet/${_id}`, {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin' : '*'
      }
    });
    const response = await data.json();

    return  response.data;
  } catch(err) {
    console.error(err);
  }
}

const getCategories = async (_id) => {
  const categories = await fetch(`${www}/api/user/load`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
  })
  .then(response => response.json())
  .then(data => data.data);
  return  categories;
}


const postDeactiveAll = async () => {
  try {
    const response = await fetch(`${www}/api/user/deactivate/all`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
    })
  } catch(err) {
    console.error(err);
  }
}


const postFlashcardResponse = async (data) => {
  try {
    const response = await fetch(`${www}/api/user/flashcard/response`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin' : '*'
      },
      body: JSON.stringify(data)
    });

    if(response.status === 200) {
      return true;
    } else {
      return false;
    }
  } catch(err) {
    console.error(err);
  }
}





export { 
  getPublicCategories, 
  getCategories, 
  getFlashcards, 
  postLogin,
  postLogout,
  postSignUp,
  flagCategory,
  getSnippet,
  checkIfLoggedIn,
  loadData,
  postDeactiveAll,
  postFlashcardResponse,
  updatePassword,
  loadFlashcards,
  resetPassword,
  verifyUser,
  resendEmailVerification
}
