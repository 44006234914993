import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import MarkDown from '../utils/MarkDown'; 
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

import { useNavigate } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';

import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


import { styled } from '@mui/material/styles';

import video from './../assets/images/demo.mp4';

// Create a styled component for the ExpandMoreIcon
const StyledExpandMoreIcon = styled(ExpandMoreIcon)({
  fontSize: '24px', // Set a fixed size for the icon
});

const infoData = [
  {
    question: "What is Radki?",
    answer: "Radki **spaced-repetition** flashcard program that " +
            "is designed specifically for **radiology residents**. At its core, Radki harnesses the power of FSRS algorithm, a " +
            "proven method for optimizing long-term retention " +
            "of information. By spacing out the review of " +
            "flashcards based on how well you know the inforation, the algorithm ensures " +
            "efficient and effective knowledge retention. This approach not " +
            "only enhances memory recall but also reinforces conceptual " +
            "understanding, making it an invaluable companion throughout " +
            "a resident's educational journey."
  },
  {
    question: "What is spaced repetition?",
    answer: "Spaced repetition is a learning technique designed to improve long-term retention of information by timing review sessions " +
      "based on how well you remember the material. The core principle behind spaced repetition is that information is reviewed at " +
      "increasing intervals, with the idea that items that are harder to remember are reviewed more frequently, while those that are easier to recall " +
      "are reviewed less often.\n\n" +

      "When you first learn a new piece of information, you review it shortly after to reinforce the memory. " +
      "As you continue to remember it, the intervals between reviews get longer, helping to transfer the information from short-term to long-term memory.\n\n" +

      "Spaced repetition is particularly effective because it leverages the 'spacing effect,' a phenomenon where " +
      "information is better retained when exposure is spaced out over time, rather than crammed in a single session. " +
      "This method not only enhances memory retention but also makes the learning process more efficient.\n\n" +
      "In essence, the algorithm **filters out what you already know** so you can **focus on what you don't know.**"
  },
  {
    question: "How to use Radki?",
    answer: `:video{src=${video}} \n` +
      "1. Click the :hotkey{menuIcon=true} icon in the left of the header to open the categories available. \n\n" +
      "2. Browse what you want to add. To activate flashcards just click on the topic you want to start memorizing. Don't add too much at one time. \n\n" +
      "3. Go to the :hotkey{studyIcon=true} icon on the right of the header. Select which set of flashcards you want to study: all, due, new or overdue. \n\n" +
      "4. Once you have finished all the flashcards in the queue it will automatically pause the study screen. You can also do this by clicking stop. \n\n",
  },
  {
    question: "What do 'Again', 'Hard', 'Medium' and 'Easy' card ratings mean?",
    answer: "To use flashcards effectively with spaced repetition, follow these steps:\n\n" +

      "First, attempt to answer the question on the flashcard. " +
      "This step is crucial for " +
      "actively engaging with the material and testing recall. \n \n" +

      "Next, reveal the answer " +
      "to see if you got it correct. This feedback is essential " +
      "for reinforcing the correct information or correcting any mistakes. \n \n" +

      "After reviewing the answer, rate the flashcard " +
      "based on how well you were able to recall the information:\n\n" +

      "**Again:** You forgot the information " +
      "or answered it incorrectly.\n\n" +

      "**Hard:** You found the information " +
      "difficult to recall but still managed to answer the flashcard correctly. \n\n" +

      "**Good:** You found the card moderately challenging to recall—neither too hard nor too easy. \n\n" +

      "**Easy:** You were easily ably to recall the information.\n\n"
  },
  {
    question: "Why are there no cards to study today?",
    answer: 'No cards are scheduled for review today. You can always add more as needed.',
  },
  {
    question: "What hotkeys are available?",
    answer: ":hotkey{spaceBar=true} Press the spacebar to reveal the answer to the flashcard. \n\n" +
        ":hotkey{one=true} Press 1 to rate the card as **Again** \n\n" +
        ":hotkey{two=true} Press 2 to rate the card as **Hard** \n\n" +
        ":hotkey{three=true} Press 3 to rate the card as **Good** \n\n" +
        ":hotkey{four=true} Press 4 to rate the card as **Easy** \n\n" +
        ":hotkey{upArrow=true} Press the upper arrow to scroll up \n\n" +
        ":hotkey{downArrow=true} Press the down arrow to scroll down \n\n",
  },
  {
    question: "What if I haven't reviewed any of the cards before starting?",
    answer: "Doesn't matter. Just review the flashcard and  rate it as **Again** if you didn't already know it.",
  },{
    question: "Future developments",
    answer: "Right now we are focused on creating flashcards and fixing any basic issues with the platform.\n\n" + 
      "If you have any suggestions let us know via our [reddit page][1].\n\n" +
      "[1]:https://www.reddit.com/r/radki/",
  },
  {
    question: "Contact Information",
    answer: "Reach out to us on Radki's [reddit page][1]. We are always looking for feedback and suggestions. \n\n" +
      "[1]:https://www.reddit.com/r/radki/",
  },

]

const Info = () => {
  const navigate = useNavigate();
  const auth = useAuth();
  console.log(infoData);

  return (
    <div>
      <div style={{ position: 'relative' }} m={4}>
        <IconButton 
          style={{ position: 'fixed', top: '10px', right: '10px' }} 
          onClick={() => { navigate('/')}}
        >
          <HighlightOffIcon fontSize="large" />
        </IconButton>
      </div>
      <Box 
        display="flex"
        flexDirection="column"
        justifyContent="flex-start"
        alignItems="center"
        height="100%"
        sx={{ marginTop: '10%' }}
        paddingBottom = {`${100}px`}
      >
        <Grid container spacing={2}>
          <Grid item xs={0} md={3} />   
          <Grid item xs={12} md={6} sx={{ textAlign: 'left' }}>
            <Box justifyContent="left" p={2} >
              <h2>
                Info
              </h2>
            </Box>

            {infoData.map((text, index) => (
              <Accordion key={index} elevation={0} sx={{ '&:before': { height: '0px' } }}>
                <AccordionSummary
                  expandIcon={<StyledExpandMoreIcon />}
                  aria-controls={`panel${index + 1}-content`}
                  id={`panel${index + 1}-header`}
                >
                  {text.question}
                </AccordionSummary>
                <AccordionDetails>
                  <MarkDown>
                    {text.answer}
                  </MarkDown>
                </AccordionDetails>
              </Accordion>
            ))}

          </Grid>
          <Grid item xs={0} md={3} />      
        </Grid>
      </Box>
    </div>
  );
};

export default Info;