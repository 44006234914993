import React from 'react';
import classNames from 'classnames';
import { SectionTilesProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import QueryBuilderIcon from '@mui/icons-material/QueryBuilder';
import BuildIcon from '@mui/icons-material/Build';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import SpeedIcon from '@mui/icons-material/Speed';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';

const propTypes = {
  ...SectionTilesProps.types
}

const defaultProps = {
  ...SectionTilesProps.defaults
}

class FeaturesTiles extends React.Component {

  render() {

    const {
      className,
      topOuterDivider,
      bottomOuterDivider,      
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      pushLeft,
      ...props
    } = this.props;

    const outerClasses = classNames(
      'features-tiles section',
      topOuterDivider && 'has-top-divider',
      bottomOuterDivider && 'has-bottom-divider',
      hasBgColor && 'has-bg-color',
      invertColor && 'invert-color',
      className
    );

    const innerClasses = classNames(
      'features-tiles-inner section-inner',
      topDivider && 'has-top-divider',
      bottomDivider && 'has-bottom-divider'
    );

    const tilesClasses = classNames(
      'tiles-wrap',
      pushLeft && 'push-left'
    );

    const sectionHeader = {
      title: '',
      paragraph: ''
    };

    return (
      <section
        {...props}
        className={outerClasses}
      >
        <div className="container">
          <div className={innerClasses}>
            <SectionHeader data={sectionHeader} className="center-content" />
            <div className={tilesClasses}>

              <div className="tiles-item reveal-from-bottom" data-reveal-container=".tiles-wrap">
                <div className="tiles-item-inner">
                  <div className="features-tiles-item-header">
                    <BuildIcon style={{ color: '#123', fontSize: 64 }} /> 
                  </div>
                  <div className="features-tiles-item-content">
                    <h4 className="mt-0 mb-8">
                      Premade flashcards (in progress)
                    </h4>
                    <p className="m-0 text-sm">
                      Focus on studying and let us handle the flashcards. We are working to cover all the topics you need to study.
                    </p>
                  </div>
                </div>
              </div>

              <div className="tiles-item reveal-from-bottom" data-reveal-container=".tiles-wrap" data-reveal-delay="100">
                <div className="tiles-item-inner">
                  <div className="features-tiles-item-header">
                      <QueryBuilderIcon style={{ color: '#123', fontSize: 64 }} />
                  </div>
                  <div className="features-tiles-item-content">
                    <h4 className="mt-0 mb-8">
                      Space repetition system
                    </h4>
                    <p className="m-0 text-sm">
                      Study smarter, not harder. Focus on what you need to learn and not what you already know.
                    </p>
                  </div>
                </div>
              </div>


              <div className="tiles-item reveal-from-bottom" data-reveal-container=".tiles-wrap" data-reveal-delay="400">
                <div className="tiles-item-inner">
                  <div className="features-tiles-item-header">
                    <SpeedIcon style={{ color: '#123', fontSize: 64 }} />
                  </div>
                  <div className="features-tiles-item-content">
                    <h4 className="mt-0 mb-8">
                      Small and fast
                    </h4>
                    <p className="m-0 text-sm">
                      Lightweight flashcards that are easy to memorize.
                    </p>
                  </div>
                </div>
              </div>

              <div className="tiles-item reveal-from-bottom" data-reveal-container=".tiles-wrap" data-reveal-delay="200">
                <div className="tiles-item-inner">
                  <div className="features-tiles-item-header">
                    <CheckBoxIcon style={{ color: '#123', fontSize: 64 }} />
                  </div>
                  <div className="features-tiles-item-content">
                    <h4 className="mt-0 mb-8">
                      Topic Selection
                    </h4>
                    <p className="m-0 text-sm">
                      Select or deselect what you want when you want.
                    </p>
                  </div>
                </div>
              </div>



              <div className="tiles-item reveal-from-bottom" data-reveal-container=".tiles-wrap" data-reveal-delay="500">
                <div className="tiles-item-inner">
                  <div className="features-tiles-item-header">
                    <LibraryBooksIcon style={{ color: '#123', fontSize: 64 }} />
                  </div>
                  <div className="features-tiles-item-content">
                    <h4 className="mt-0 mb-8">
                      ABR Core and RCPSC Specialty Exam Preparation
                    </h4>
                    <p className="m-0 text-sm">
                      Study for the ABR Core and RCPSC Specialty Exams with our premade flashcards.
                    </p>
                  </div>
                </div>
              </div>              

            </div>
          </div>
        </div>
      </section>
    );
  }
}

FeaturesTiles.propTypes = propTypes;
FeaturesTiles.defaultProps = defaultProps;

export default FeaturesTiles;