import { useEffect, useState } from 'react';
import './App.css';
import { 
  BrowserRouter as Router, 
  Route, 
  Routes,
  Navigate
} from "react-router-dom";

import './assets/scss/style.scss';

// Layouts
import LayoutDefault from './layouts/LayoutDefault';
import LayoutSignin from './layouts/LayoutSignin';

// Views 
import Home from './pages/Home';
import Login from './pages/Login';
import Signup from './pages/Signup';
import Dashboard from './pages/dashboard';
import Profile from './pages/Profile';
import Stats from './pages/Stats';
import ForgotPassword from './pages/ForgotPassword';
import VerifyUser from './pages/VerifyUser';
import Info from './pages/Info';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsAndConditions from './pages/TermsAndConditions';

// Hooks
import { useAuth }   from './hooks/useAuth';

function App() {
  const [loading, setLoading] = useState(true);
  const auth = useAuth();

  useEffect(() => {
    const checkAuthStatus = async () => {
      if (auth === undefined) {
        // auth is still loading
        setLoading(true);
      } else {
        if(auth) {
          await auth.checkLogin();
          setLoading(false);
        }
        setLoading(false);
      }
    };

    checkAuthStatus();
  }, [auth, auth.isLoggedIn]);

  return (
    <div className="App">
      {
        loading ? null :
        <Router>
          <Routes>
            <Route 
            exact 
            path="/" 
            element={auth.isLoggedIn 
              ? <Dashboard />
              : <Home />} 
            layout={LayoutDefault}/>
            <Route exact path="/login" element={auth.isLoggedIn ? <Navigate to="/" />  : <Login />} layout={LayoutSignin}/>
            <Route exact path="/signup" element={auth.isLoggedIn ? <Navigate to="/" />   : <Signup />} layout={LayoutSignin}/>
            <Route exact path="/profile" element={auth.isLoggedIn ? <Profile /> : <Login />} layout={LayoutSignin}/>
            <Route exact path="/info" element={auth.isLoggedIn ? <Info /> : <Login />} layout={LayoutSignin}/>
            <Route exact path="/stats" element={auth.isLoggedIn ? <Stats /> : <Login />} layout={LayoutSignin}/>
            <Route exact path="/recover-password" element={auth.isLoggedIn ? <Navigate to="/" />    : <ForgotPassword />} layout={LayoutSignin}/>
            <Route exact path="/verify-user/:id" element={auth.isLoggedIn ? <Navigate to="/" />   : <VerifyUser />} layout={LayoutSignin}/>            
            <Route exact path="/privacy-policy" element={<PrivacyPolicy />} layout={LayoutSignin}/>
            <Route exact path="/terms-and-conditions" element={<TermsAndConditions />} layout={LayoutSignin}/>
          </Routes>
        </Router>
      }
    </div>
  );
}

export default App;
