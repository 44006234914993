import React from 'react';
import { useState } from 'react';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
import { Link } from 'react-router-dom';
import SectionHeader from './partials/SectionHeader';
import Input from '../elements/Input';
import Button from '../elements/Button';
import { useAuth } from '../../hooks/useAuth';
import { useNavigate } from 'react-router-dom'; 
import { resetPassword } from '../../api/index';

const propTypes = {
  ...SectionProps.types
}

const defaultProps = {
  ...SectionProps.defaults
}

const ForgotPasswordForm = (props) => {
  const [email, setEmail] = useState('');
  const navigate = useNavigate();

  const auth = useAuth();

  const {
    className,
    topOuterDivider,
    bottomOuterDivider,      
    topDivider,
    bottomDivider,
    hasBgColor,
    invertColor,
    ...otherProps
  } = props;

  const outerClasses = classNames(
    'signin section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'signin-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const sectionHeader = {
    title: 'Recover Password.',
  };

  const submit = async (event) => {
    // prevent browser reload/refresh
    event.preventDefault();

    try {
      console.log(email);
      const response = await resetPassword({email});
      if(response) {
        navigate('/login');
      }
    } catch(err) {
      console.log(err);
    }
  }

  if(!auth) return;

  return (
    <section
      {...otherProps}
      className={outerClasses}
    >
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader tag="h1" data={sectionHeader} className="center-content" />
          <div className="tiles-wrap">
            <div className="tiles-item">
              <div className="tiles-item-inner">
                <form>
                  <fieldset>
                    <div className="mb-12">
                      <Input
                        type="email"
                        label="Email"
                        placeholder="Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        labelHidden
                        required />
                    </div>
                    <div className="mt-24 mb-32">
                      <Button color="primary" wide onClick={(event) => submit(event)}>Sign in</Button>
                    </div>
                  </fieldset>
                </form>
                <div className="signin-bottom has-top-divider">
                  <div className="pt-32 text-xs center-content text-color-low">
                    Don't you have an account? <Link to="/signup/" className="func-link">Sign up</Link>
                  </div>
                  <div className="pt-32 text-xs center-content text-color-low">
                    <Link to="/" className="func-link text-xs">Home</Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

ForgotPasswordForm.propTypes = propTypes;
ForgotPasswordForm.defaultProps = defaultProps;

export default ForgotPasswordForm;