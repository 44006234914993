import * as React from 'react';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import { useStudy } from '../hooks/useStudy';

import ConstructionIcon from '@mui/icons-material/Construction';

const Stats = () => {
  const navigate = useNavigate();
  const study = useStudy();

  const deactivateAllCards = async () => {
    console.log(study);
    let _flashcards = {...study.flashcards};

    for(let x = 0; x < Object.keys(_flashcards).length; x++) {
      _flashcards[Object.keys(_flashcards)[x]].active = false;
    }

    study.setFlashcards(_flashcards);

    await study.deactivateAll();

    navigate("/");
  }


  return (
    <div>
      <div style={{ position: 'relative' }} m={4}>
        <IconButton 
          style={{ position: 'fixed', top: '10px', right: '10px' }} 
          onClick={() => { navigate('/')}}
        >
          <HighlightOffIcon fontSize="large" />
        </IconButton>
      </div>
      <Box 
        display="flex"
        flexDirection="column"
        justifyContent="flex-start"
        alignItems="center"
        height="100%"
        sx={{ marginTop: '10%' }}
        paddingBottom = {`${100}px`}
      >
        <Grid container spacing={2}>
          <Grid item xs={0} md={3} />   
          <Grid item xs={12} md={6} sx={{ textAlign: 'left' }}>
            <Box justifyContent="left" p={2} >
              <h2>
                Stats
              </h2>
            </Box>
            <Box justifyContent="center" p={2}>
              <Divider style={{ width: '95%' }} /> {/* Adjust the width as needed */}
            </Box>

            <Box 
              display="flex" 
              flexDirection="column" 
              alignItems="center" 
              justifyContent="center" 
              p={2} 
              textAlign="center"
            >
              <div>Under Construction</div>
              <ConstructionIcon sx={{ fontSize: 200 }} />
            </Box>

            <Box justifyContent="left" p={2} >
              <h4>
                Manage Cards
              </h4>
              <Button variant="contained" color="error" onClick={deactivateAllCards}>
                Deactive All Cards
              </Button>
            </Box>
          </Grid>
          <Grid item xs={0} md={3} />      
        </Grid>
      </Box>
    </div>
  );
};

export default Stats;