import React from 'react';
import VerifyUserSection from '../components/sections/VerifyUserSection';

class VerifyUser extends React.Component {
  render() {
    return (
      <VerifyUserSection className="illustration-section-01" />
    );
  }
}

export default VerifyUser;