import React, { useEffect, useState } from 'react';

import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';

// icons
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { styled } from '@mui/material/styles';

// hooks
import { useStudy } from '../hooks/useStudy';

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

const Categories = (props) => {
  const [displayCategories, setDisplayCategories] = useState([]);
  const [parent, setParent] = useState(null);
  const study = useStudy();

  // disable checkbox and prevent user from firing multiple asynchronous updates
  const [disabled, setDisabled] = useState(false);

  const updateDisplayCategories = () => {
    const keys = Object.keys(study.categories);
    
    if(parent === null) {
      let roots = [];
      for(let x = 0; x < keys.length; x++) {
        if(study.categories[keys[x]].parent === null) {
          roots = [...roots, study.categories[keys[x]]];
        }
      }

      setDisplayCategories(roots);
    } else {
      let newCategories = [];

      const children = study.categories[parent].children.filter( c => c.published ).map( c => c.category);
      console.log(children);

      for(let x = 0; x < children.length; x++) {
        if(children.length > 0) {
          newCategories.push(study.categories[children[x]]);  
        }
      }

      newCategories = newCategories.filter( c => Boolean(c));

      setDisplayCategories(newCategories);
      console.log(newCategories);
    }
  }

  const upArrow = () => {
     if(parent !== null) {
      return (
        <IconButton onClick={() => toggleUpArrow(parent)}>
          <KeyboardArrowUpIcon />
        </IconButton>)
    } else {
      return null;
    }
  }

  useEffect(() => {
    updateDisplayCategories();
  }, [parent, study.categories]);

  const toggleUpArrow = (_id) => {
    if(_id === null) return;
    const category = study.categories[_id];
    const keys = Object.keys(study.categories);
    let showCategories = {};

    if(category.parent !== null) {
      let parent = study.categories[category.parent];

      keys.forEach( i => {
        if (parent.children.includes(study.categories[i]._id)) {
          showCategories[i] = study.categories[i];
        }
      });

      setParent(category.parent);
    } else {
      keys.forEach( i => {
        if (study.categories[i].parent) {
          showCategories[i] = study.categories[i];
        }
      });

      setParent(null);
    }
  }

  const categoryHasPublicChildren = (_id) => {
    const category = study.categories[_id];

    const children = category.children
      .filter( c => c.published )
      .map( c => c.category);

    return children.length > 0;
  }

    const updateParent = (_id) => {
    if(!categoryHasPublicChildren(_id)) return;
    setParent(_id);
  }

  const listItemClickAction = (category) => {
    if(disabled) return null;

    return category?.snippets && category?.snippets?.length > 0 
    ? async () => await addFlashcards(category._id) 
    : () => updateParent(category._id)
  }

  const addFlashcards = async (_id) => {
    setDisabled(true);

    await study.flagFlashards(_id);

    setDisabled(false);
  }

  const countPublishedSnippets = (_id) => {
    const category = study.categories[_id];

    return category.snippets.filter( s => s.published ).length;
  }


  return (
    <>
      <DrawerHeader>
        {upArrow()}
        <IconButton onClick={() => props.toggleDrawer()}>
          {< HighlightOffIcon />}
        </IconButton>
      </DrawerHeader>
      <List>
      {
        displayCategories.map(category => (
          <ListItemButton 
            onClick={listItemClickAction(category)}>
              <Grid container alignItems="center" direction="row" wrap="nowrap">
                <Grid item>
                  <Checkbox 
                    checked={Boolean(category?.flagged)} 
                    disabled={disabled} 
                    sx={{ width: 50, height: 50 }} />
                </Grid>
                <Grid item>
                  <ListItemText primary={category.title} sx={{ paddingLeft: 2, paddingRight: 2 }} />
                </Grid>
              </Grid>
              {category.snippets && countPublishedSnippets(category._id) > 0 ? `(${countPublishedSnippets(category._id)})` : null}
          </ListItemButton>
        ))
      }
      </List>
    </>
  );
}


export default Categories;
