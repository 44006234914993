import { useState } from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import remarkMath from 'remark-math';
import rehypeKatex from 'rehype-katex';
import remarkDirective from 'remark-directive';
import { InputBase } from '@mui/material';
import {visit} from 'unist-util-visit';
import {h} from 'hastscript';
import 'katex/dist/katex.min.css';
import './style.css';
import Label from './label';
import Image from '../Image';
import SpaceBarIcon from '@mui/icons-material/SpaceBar';
import LooksOneIcon from '@mui/icons-material/LooksOne';
import LooksTwoIcon from '@mui/icons-material/LooksTwo';
import Looks3Icon from '@mui/icons-material/Looks3';
import Looks4Icon from '@mui/icons-material/Looks4';
import ArrowUpwardRoundedIcon from '@mui/icons-material/ArrowUpwardRounded';
import ArrowDownwardRoundedIcon from '@mui/icons-material/ArrowDownwardRounded';
import MenuIcon from '@mui/icons-material/Menu';
import LocalLibraryIcon from '@mui/icons-material/LocalLibrary';


function plugins(props) {
  return (tree) => {
    visit(tree, (node) => {
      if (
        node.type === 'textDirective' ||
        node.type === 'leafDirective' ||
        node.type === 'containerDirective'
      ) {
        const data = node.data || (node.data = {})
        const hast = h(node.name, node.attributes)

        data.hName = hast.tagName
        data.hProperties = hast.properties
      }
    })
  }
}

/**
 * ImageDisplay component to display a series of images with optional captions and scrolling functionality.
 * @param {Object} props - The properties passed to the component.
 * @param {Array} props.series - The series of images to display.
 * @param {boolean} [props.thumbnail=false] - Whether to display images as thumbnails.
 * @param {boolean} [props.imageOnly=false] - Whether to display only the image.
 * @param {boolean} [props.captionOnly=false] - Whether to display only the caption.
 * @returns {JSX.Element} The rendered component.
 */

function MarkDown(props) {
  const Cloze = (data) => {
    const [input, setInput] = useState('');
    const [color, setColor] = useState('info');
    const front = data.front ? data.front : '';
    const back = data.back ? data.back : '';
    const maxLength = (front.length > back.length
      ? front.length
      : back.length) + 1;

    const onChange = (e) => {
      const correct = e.target.value === back;
      setInput(e.target.value);
      setColor(correct ? 'success' : 'info');
    }

    const showAnswer = () => {
      setInput(back);
      setColor('success');
    }

    const hideAnswer = () => {
      setInput('');
      setColor('info');
    }

    props?.showAnswer ? showAnswer() : hideAnswer();

    return (
      <Label color={color}>
        <InputBase
          color={color}
          placeholder={front}
          value={input}
          onChange={onChange}
          inputProps={{ style: { textAlign: 'center', width: `${maxLength}ch`} }}
        />
      </Label>
    );
  }

  const HotKey = (props) => {
    if(props.spaceBar) {
      return <SpaceBarIcon />
    } else if(props.one) {
      return <LooksOneIcon />
    } else if(props.two) {
      return <LooksTwoIcon />
    } else if(props.three) {
      return <Looks3Icon />
    } else if(props.four) {
      return <Looks4Icon />
    } else if(props.upArrow) {
      return <ArrowUpwardRoundedIcon />
    } else if(props.downArrow) {
      return <ArrowDownwardRoundedIcon />
    } else if(props.menuIcon) {
      return <MenuIcon />
    } else if(props.studyIcon) {
      return <LocalLibraryIcon />
    }
  }

  const Video = (props) => {
    if(!props?.src) {
      return null;
    }

    return (
      <video
        width="600"
        height="600"
        autoPlay
        loop
        muted
        style={{ boxShadow: '0px 10px 15px rgb(0 , 0, 0)', borderRadius: '10px' }}
      >
        <source src={props.src} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    );
  }

  const components = {
    cloze: Cloze,
    img: (data) => <Image {...props } {...data} />,
    p: 'p',
    hotkey: HotKey,
    video: (data) => <Video {...props } {...data} />,
  }
 

  return (
    <ReactMarkdown
      children={props.children}
      components={components}
      remarkPlugins={[remarkGfm, remarkMath, remarkDirective, plugins]}
      rehypePlugins={[rehypeKatex]}
    />
  )
}


export default MarkDown
