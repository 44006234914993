import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import MarkDown from '../utils/MarkDown'; 
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

import { useNavigate } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';


import PrivacyPolicyText from './PrivacyPolicyText';

const PrivacyPolicy = () => {
  const navigate = useNavigate();
  const auth = useAuth();

  return (
    <div>
      <div style={{ position: 'relative' }} m={4}>
        <IconButton 
          style={{ position: 'fixed', top: '10px', right: '10px' }} 
          onClick={() => { navigate('/')}}
        >
          <HighlightOffIcon fontSize="large" />
        </IconButton>
      </div>
      <Box 
        display="flex"
        flexDirection="column"
        justifyContent="flex-start"
        alignItems="center"
        height="100%"
        sx={{ marginTop: '10%' }}
        paddingBottom = {`${100}px`}
      >
        <Grid container spacing={2}>
          <Grid item xs={0} md={3} />   
          <Grid item xs={12} md={6} sx={{ textAlign: 'left' }}>
            <Box justifyContent="left" p={2} >
              <h2>
                Privacy Policy
              </h2>
            </Box>

            <Box>
                <MarkDown>
                    {PrivacyPolicyText}
                </MarkDown>
            </Box>
            

          </Grid>
          <Grid item xs={0} md={3} />      
        </Grid>
      </Box>
    </div>
  );
};

export default PrivacyPolicy;