export function getStartAndEndTimes(date) {
    // Create new Date objects from the input date
    let startOfDay = new Date(date);
    let endOfDay = new Date(date);

    // Set the time to the start of the day
    startOfDay.setHours(0, 0, 0, 0);

    // Set the time to the end of the day
    endOfDay.setHours(23, 59, 59, 999);

    return { startOfDay, endOfDay };
}