const { www } = require('../../config.js').config;

/**
 * Asynchronously logs in a user with the provided email and password.
 *
 * @param {Object} credentials - The user's login credentials.
 * @param {string} credentials.email - The user's email address.
 * @param {string} credentials.password - The user's password.
 * @returns {Promise<Object>} A promise that resolves to an object containing the response status and data if successful, or an object containing the response status and error message if unsuccessful.
 *
 * @throws {Error} If the fetch request fails or the response is not successful.
 */
const postLogin = async ({ email, password }) => {
    try {
      const response = await fetch(`${www}/api/user/login`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': www
        },
        body: JSON.stringify({ email, password })
      });
  
      const info = await response.json();
  
      if (response.status === 200) {
        return {
          status: response.status,
          ...info.data
        };
      } else {
        throw new Error(`Login failed: ${info.message}`);
      }
    } catch (err) {
      console.error('Error during login:', err);
      throw new Error('An error occurred during login. Please try again later.');
    }
  };

/**
 * Asynchronously logs out the current user.
 *
 * @returns {Promise<boolean>} A promise that resolves to true if the logout was successful, or throws an error if the logout failed.
 *
 * @throws {Error} If the fetch request fails or the response is not successful.
 */
const postLogout = async () => {
    try {
      const response = await fetch(`${www}/api/user/logout`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*'
        }
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
        return false;
      } else {
        return true;
      }
    } catch (err) {
      console.error(err);
      throw new Error('An error occurred during logout. Please try again later.');
    }
};

/**
 * Asynchronously resends the email verification to the specified email address.
 *
 * @param {Object} params - The parameters for the function.
 * @param {string} params.email - The email address to which the verification email will be resent.
 * @returns {Promise<boolean>} A promise that resolves to true if the email was successfully resent, or false if an error occurred.
 *
 * @throws {Error} If the fetch request fails or the response is not successful.
 */
const resendEmailVerification = async ({ email }) => {
    try {
      const response = await fetch(`${www}/api/user/resend-verification-email`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': www
        },
        body: JSON.stringify({ email })
      });
  
      if (response.status === 200) {
        return true;
      } else {
        return false;
      }
    } catch (err) {
      console.error(err);
      return false;
    }
};

/**
 * Asynchronously sends a sign-up request to the server.
 *
 * @param {Object} data - The sign-up data to be sent to the server.
 * @param {string} data.username - The username of the new user.
 * @param {string} data.password - The password of the new user.
 * @param {string} data.email - The email address of the new user.
 * @returns {Promise<Response>} A promise that resolves to the server's response.
 *
 * @throws {Error} If the fetch request fails.
 */
const postSignUp = async (data) => {
  try {
    const response = await fetch(`${www}/api/auth/register`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': www
      },
      body: JSON.stringify(data)
    });

    return response;
  } catch (err) {
    console.log(err);
    throw new Error('Sign-up request failed.');
  }
};

/**
 * Asynchronously checks if the user is logged in by verifying the authentication cookie.
 *
 * @returns {Promise<Object|boolean|string>} A promise that resolves to the response JSON if the user is logged in, false if not, or an error message if an error occurs.
 */
const checkIfLoggedIn = async () => {
  try {
    const response = await fetch(`${www}/api/auth/checkcookie`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': www
      },
    });

    if (response.status === 200) {
      return await response.json();
    } else {
      return false;
    }
  } catch (err) {
    console.log(err);
    return err.msg;
  }
};

/**
 * Asynchronously verifies a user by their ID.
 *
 * @param {Object} params - The parameters for the function.
 * @param {string} params.id - The ID of the user to be verified.
 * @returns {Promise<boolean>} A promise that resolves to true if the user was successfully verified, or false if an error occurred.
 *
 * @throws {Error} If the fetch request fails.
 */
const verifyUser = async ({ id }) => {
  try {
    const response = await fetch(`${www}/api/user/verify/${id}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': www
      },
      body: JSON.stringify({ id })
    });

    return response.status === 200;
  } catch (err) {
    console.error(err);
    return false;
  }
};


/**
 * Asynchronously updates the user's password.
 *
 * @param {Object} data - The data for updating the password.
 * @param {string} data.oldPassword - The user's current password.
 * @param {string} data.newPassword - The new password to be set.
 * @returns {Promise<Response|boolean>} A promise that resolves to the server's response, or false if an error occurred.
 *
 * @throws {Error} If the fetch request fails.
 */
const updatePassword = async (data) => {
  try {
    const response = await fetch(`${www}/api/user/update/password`, {
      method: 'PUT',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': www
      },
      body: JSON.stringify(data)
    });
    if(response.status === 200) {
      return true;
    } else {
      return new Error('Password update failed.');
    }
  } catch (err) {
    console.error(err);
    return err;
  }
};

/**
 * Asynchronously sends a password reset request for the specified email.
 *
 * @param {Object} params - The parameters for the function.
 * @param {string} params.email - The email address for which to reset the password.
 * @returns {Promise<Response|boolean>} A promise that resolves to the server's response, or false if an error occurred.
 *
 * @throws {Error} If the fetch request fails.
 */
const resetPassword = async ({ email }) => {
  try {
    const response = await fetch(`${www}/api/user/forgot-password`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': www
      },
      body: JSON.stringify({ email })
    });

    if(response.status === 200) {
      return true;
    } else {
      return new Error('Password reset failed.');
    }
  } catch (err) {
    console.error(err);
    return false;
  }
};


module.exports = {
    postLogin,
    postLogout,
    postSignUp,
    checkIfLoggedIn,
    postSignUp,
    verifyUser,
    resendEmailVerification,
    updatePassword,
    resetPassword
}